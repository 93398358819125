import mediaTypes from '../constants/mediaTypes.constants';
import validatorsUtils from './validators.utils';

const isAddressValid = (companyAddress) => {
  if (!companyAddress) {
    return false;
  }

  const {
    street,
    city,
    zipCode,
  } = companyAddress;

  return !!street?.trim()
    && !!city?.trim()
    && !!zipCode?.trim();
};

const isActivityValid = (activity) => {
  if (!activity) {
    return false;
  }

  const {
    id,
    categoryId,
  } = activity;

  return !!id?.trim()
    && categoryId?.trim();
};

const hasImages = (medias) => {
  if (!medias?.length) {
    return false;
  }

  return !!medias
    .filter((m) => m.mediaType === mediaTypes.IMAGE)
    .length;
};

const hasOpenings = (openings) => {
  if (!openings) {
    return false;
  }

  const days = Object.entries(openings)
    .filter(([, value]) => Array.isArray(value))
    .reduce((acc, [key, value]) => ({ ...acc, [key]: value }), {});

  return Object.values(days)
    .some((day) => !!day.length);
};

const isStringValid = (value) => !!value?.trim();

const validate = (company, hasInvoiceAddress = false) => {
  if (!company) {
    return { valid: false };
  }

  const { showOnMap, address } = company;
  const { country } = address;

  const validators = {
    name: isStringValid,
    vat: (vat) => validatorsUtils.isVATNumberValid(vat, country),
    activity: isActivityValid,
    address: isAddressValid,
    billingAddress: (add) => (hasInvoiceAddress ? isAddressValid(add) : true),
    openings: (openings) => (showOnMap ? hasOpenings(openings) : true),
    medias: hasImages,
    descriptionOffer: isStringValid,
    descriptionPhilo: isStringValid,
    retailerFirstName: isStringValid,
    retailerLastName: isStringValid,
  };

  const unvalidFields = Object.entries(validators)
    .reduce((acc, [key, value]) => {
      const isFieldValid = value(company[key]);

      return isFieldValid ? acc : [...acc, key];
    }, []);

  return {
    valid: !unvalidFields.length,
    unvalidFields,
  };
};

export default {
  validate,
};
