<template>
  <div>
    <div class="row" v-if="isAdmin">
      <div class="col col-12 mt-2">
        <label for>Statut de l&apos;entreprise</label>
        <select class="lowco-textbox" :value="company.status" @change="onStatusChange">
          <option v-for="status in statuses" :key="status" :value="status">{{ status }}</option>
        </select>
      </div>
    </div>

    <div class="profile">
      <div class="form">
        <div class="column-span-6">
          <label class="bold" for="mail">Adresse mail</label>
          <input disabled type="text" class="lowco-textbox" :value="enterprise.mail" />
        </div>
        <div class="column-span-3">
          <label class="bold" for="name">Nom commercial</label>
          <input
            required
            type="text"
            id="name"
            name="name"
            :class="['lowco-textbox', { 'lowco-textbox-error': !isFieldValid('name') }]"
            maxlength="254"
            @change="changeFormValue"
            v-model="enterprise.name"
          />
        </div>
        <div class="column-span-3">
          <label class="bold" for="vat">Numéro de TVA</label>
          <input
            required
            type="text"
            id="vat"
            name="vat"
            :class="['lowco-textbox', { 'lowco-textbox-error': !isFieldValid('vat') }]"
            @change="onVatChange"
            :value="enterprise.vat"
            v-cleave="{ delimiters: [' ', '.'], blocks: [2, 4, 3, 3], uppercase: true }"
          />
        </div>
        <div class="column-span-3">
          <label class="bold" for="retailerFirstName">Prénom</label>
          <input
            required
            type="text"
            id="retailerFirstName"
            name="retailerFirstName"
            :class="[
              'lowco-textbox',
              { 'lowco-textbox-error': !isFieldValid('retailerFirstName') },
            ]"
            @change="changeFormValue"
            v-model="enterprise.retailerFirstName"
          />
        </div>
        <div class="column-span-3">
          <label class="bold" for="retailerLastName">Nom</label>
          <input
            required
            type="text"
            id="retailerLastName"
            name="retailerLastName"
            :class="['lowco-textbox', { 'lowco-textbox-error': !isFieldValid('retailerLastName') }]"
            @change="changeFormValue"
            v-model="enterprise.retailerLastName"
          />
        </div>
        <div class="column-span-2">
          <label class="bold" for="category">Catégorie</label>
          <select
            id="category"
            name="category"
            :class="['lowco-textbox', { 'lowco-textbox-error': !isFieldValid('activity') }]"
            required
            v-model="selectedCategory"
            @change="changeFormCategory"
          >
            <option v-for="category in categories" :key="category.id" :value="category.id">{{
              category.name
            }}</option>
          </select>
        </div>
        <div class="column-span-2">
          <label for="activity">Activité</label>
          <select
            id="activity"
            name="activity"
            :class="['lowco-textbox', { 'lowco-textbox-error': !isFieldValid('activity') }]"
            required
            v-model="selectedActivity"
            @change="onActivityChange"
          >
            <option v-for="activity in currentActivities" :key="activity.id" :value="activity.id">{{
              activity.name
            }}</option>
          </select>
        </div>
        <div class="column-span-2">
          <label for="websiteUrl">Site web (https://)</label>
          <input
            id="websiteUrl"
            name="websiteUrl"
            type="text"
            required
            class="lowco-textbox"
            @change="changeFormValue"
            v-model="enterprise.websiteUrl"
          />
        </div>
        <div class="column-span-6">
          <div>Je vends :</div>
          <Toggle
            v-model="enterprise.showOnMap"
            @update:modelValue="changeFormValue"
            leftText="Uniquement en ligne"
            rightText="En magasin"
          />
        </div>
        <strong class="column-span-6 text">Adresse</strong>
        <div class="column-span-3">
          <label for="street">Rue et numéro</label>
          <input
            id="street"
            name="street"
            required
            type="text"
            :class="['lowco-textbox', { 'lowco-textbox-error': !isFieldValid('address') }]"
            @change="changeFormAddress"
            v-model="enterprise.address.street"
          />
        </div>
        <div class="column-span-1">
          <label for="zipCode">C.P.</label>
          <input
            id="zipCode"
            name="zipCode"
            required
            type="text"
            :class="['lowco-textbox', { 'lowco-textbox-error': !isFieldValid('address') }]"
            @change="changeFormAddress"
            v-model="enterprise.address.zipCode"
          />
        </div>
        <div class="column-span-2">
          <label for="city">Ville</label>
          <input
            id="city"
            name="city"
            required
            type="text"
            :class="['lowco-textbox', { 'lowco-textbox-error': !isFieldValid('address') }]"
            @change="changeFormAddress"
            v-model="enterprise.address.city"
          />
        </div>
        <div class="column-span-6">
          <label :for="'addressSameAs'" class="lowco-checkbox">
            L'adresse de facturation est identique
            <input
              type="checkbox"
              id="addressSameAs"
              @change="changeFormValue"
              v-model="addressSameAs"
            />
            <span class="checkmark"></span>
          </label>
        </div>
        <template v-if="!addressSameAs">
          <strong class="column-span-6">Adresse de facturation</strong>
          <div class="column-span-3">
            <label for="billingStreet">Rue</label>
            <input
              id="billingStreet"
              :required="!addressSameAs"
              type="text"
              :class="['lowco-textbox', { 'lowco-textbox-error': !isFieldValid('billingAddress') }]"
              @change="changeFormValue"
              v-model="enterprise.billingAddress.street"
            />
          </div>
          <div class="column-span-1">
            <label for="billingZipCode">C.P.</label>
            <input
              id="billingZipCode"
              :required="!addressSameAs"
              type="text"
              :class="['lowco-textbox', { 'lowco-textbox-error': !isFieldValid('billingAddress') }]"
              @change="changeFormValue"
              v-model="enterprise.billingAddress.zipCode"
            />
          </div>
          <div class="column-span-2">
            <label for="billingCity">Ville</label>
            <input
              id="billingCity"
              :required="!addressSameAs"
              type="text"
              :class="['lowco-textbox', { 'lowco-textbox-error': !isFieldValid('billingAddress') }]"
              @change="changeFormValue"
              v-model="enterprise.billingAddress.city"
            />
          </div>
        </template>
        <div class="column-span-3">
          <label class="bold" for="phoneNumber">Numéro de téléphone</label>
          <input
            id="phoneNumber"
            name="phoneNumber"
            required
            type="text"
            class="lowco-textbox"
            @blur="(e) => {
              enterprise.phoneNumber = e.target.value;
              
              changeFormValue(e);
            }"
            :value="enterprise.phoneNumber"
            v-cleave="{
              phone: true,
              phoneRegionCode: 'BE',
            }"
          />
        </div>
      </div>
      <div class="map">
        <div id="map"></div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable global-require */
/* eslint-disable import/no-dynamic-require */
/* eslint-disable no-undef */

import 'cleave.js/dist/addons/cleave-phone.be';

import lowcoApi from '@/api/lowco-api';
import nominatimApi from '@/api/nominatim-api';
import enterprisesConstants from '@/constants/enterprises.constants';
import useAuthenticatedUser from '@/composables/useAuthenticatedUser';

import Toggle from '@/components/common/Toggle.vue';

export default {
  name: 'Profile',
  props: {
    company: Object,
    unvalidFields: Array,
  },
  components: {
    Toggle,
  },
  setup() {
    const { isAdmin } = useAuthenticatedUser();

    return { isAdmin };
  },
  data() {
    return {
      enterprise: { ...this.company },
      addressSameAs: true,
      isSaving: false,
      isLoading: false,
      hasError: false,
      icons: [],
      iconsGuest: [],
      categories: [],
      selectedCategory: null,
      activities: {},
      selectedActivity: null,
      marker: null,
      isConfirmationActive: false,
      defaultIcon: null,
    };
  },
  computed: {
    enterpriseAddress() {
      return `${this.enterprise.address.street} ${this.enterprise.address.zipCode} ${this.enterprise.address.city}`;
    },
    statuses() {
      return [
        enterprisesConstants.PILOT,
        enterprisesConstants.GUEST,
        enterprisesConstants.DELETED,
        enterprisesConstants.ACTIVE,
      ];
    },
    currentActivities() {
      if (!this.selectedCategory) {
        return [];
      }

      return this.activities[this.selectedCategory];
    },
  },
  watch: {
    company(value) {
      this.enterprise = { ...value };

      if (value.billingAddress?.street) {
        this.addressSameAs = false;
      }
    },
    categories(value) {
      if (!value.length) {
        return;
      }

      this.initIcons();
    },
    selectedCategory(value) {
      if (!value) {
        return;
      }

      if (!this.activities[value]) {
        this.loadActivities(value);
      }
    },
    addressSameAs(value) {
      if (!value) {
        return;
      }

      this.enterprise.billingAddress = {
        street: '',
        zipCode: '',
        city: '',
      };
    },
  },
  methods: {
    isFieldValid(fieldName) {
      return !this.unvalidFields.includes(fieldName);
    },
    async setMapMarker(loaded) {
      const address = await nominatimApi.getCoordinates(this.enterpriseAddress);
      address.data = address.data.filter(
        (d) =>
          d.display_name.includes('Belgique') ||
          d.display_name.includes('Wallonie') ||
          d.display_name.includes('Flandre') ||
          d.display_name.includes('Bruxelles-Capitale'),
      );

      if (address.data && address.data.length) {
        const latitude = loaded ? this.enterprise.latitude : address.data[0].lat;
        const longitude = loaded ? this.enterprise.longitude : address.data[0].lon;

        this.enterprise.latitude = latitude;
        this.enterprise.longitude = longitude;

        this.map.setView([latitude, longitude], 15);

        try {
          if (this.marker) {
            this.map.removeLayer(this.marker);
          }
        } catch {
          //
        }

        if (this.enterprise.activity) {
          this.marker = L.marker([latitude, longitude], {
            icon: this.icons[this.enterprise.activity.categoryId] || this.defaultIcon,
            draggable: true,
          });
          this.marker.on('dragend', (event) => {
            const marker = event.target;
            const position = marker.getLatLng();
            this.enterprise.latitude = position.lat;
            this.enterprise.longitude = position.lng;
          });
          this.marker.addTo(this.map);
        }
      } else {
        this.map.setView([0, 0], 11);
      }
    },
    onVatChange(event) {
      this.enterprise.vat = event.target.value;

      this.onEnterpriseChange();
    },
    onEnterpriseChange() {
      if (!this.enterprise) {
        return;
      }

      this.$emit('company-change', this.enterprise, !this.addressSameAs);
    },
    onStatusChange(event) {
      this.$emit('status-change', event.target.value);
    },
    changeFormValue() {
      this.onEnterpriseChange();
    },
    async changeFormCategory() {
      await this.setMapMarker();
      this.changeFormValue();
    },
    async changeFormAddress() {
      await this.setMapMarker();
      this.changeFormValue();
    },
    async loadCategories() {
      try {
        const result = await lowcoApi.getCompanyCategories();

        this.categories = result;
      } catch (error) {
        console.log(error);
      }
    },
    async loadActivities(categoryId) {
      try {
        const result = await lowcoApi.getCategoryActivities(categoryId);

        this.activities = {
          ...this.activities,
          [categoryId]: result,
        };
      } catch (error) {
        console.log(error);
      }
    },
    async initIcons() {
      this.defaultIcon = L.icon({
        iconUrl: require('@/assets/images/map/Unknown.png'),
        iconSize: [30, 37],
        iconAnchor: [24, 17],
        popupAnchor: [-8, -20],
      });

      this.categories.forEach((category) => {
        let image;

        try {
          image = require(`@/assets/images/map/icon_${category.id}.png`);
        } catch {
          image = require('@/assets/images/map/Unknown.png');
        }

        this.icons[category.id] = L.icon({
          iconUrl: image,
          iconSize: [30, 37],
          iconAnchor: [24, 17],
          popupAnchor: [-8, -20],
        });
      });

      if (this.enterprise) {
        await this.setMapMarker(true);
      } else {
        this.map.setView([0, 0], 11);
      }
    },
    initMap() {
      this.map = L.map('map', { dragging: !L.Browser.mobile });

      L.tileLayer(
        'https://tile.jawg.io/jawg-light/{z}/{x}/{y}.png?access-token=0gsOgmARt4XUjiWssCZuoXD3cCKp9lHTVPsk1qOxtRpKjpbNzEs2QpxOkfgP9PyJ',
        {},
      ).addTo(this.map);
      this.map.attributionControl.addAttribution(
        '<a href="https://www.jawg.io" target="_blank">&copy; Jawg</a> - <a href="https://www.openstreetmap.org" target="_blank">&copy; OpenStreetMap</a>',
      );
    },
    onActivityChange() {
      const { name } = this.activities[this.selectedCategory].find(
        (x) => x.id === this.selectedActivity,
      );

      this.enterprise = {
        ...this.enterprise,
        activity: {
          name,
          id: this.selectedActivity,
          categoryId: this.selectedCategory,
        },
      };

      this.changeFormValue();
    },
  },
  async mounted() {
    this.initMap();
    this.loadCategories();
    this.$emit('company-change', null, !this.addressSameAs);

    this.selectedCategory = this.enterprise.activity?.categoryId;
    this.selectedActivity = this.enterprise.activity?.id;
  },
};
</script>

<style lang="scss" scoped>
@import '../../assets/styles/common/variables.scss';
@import '../../assets/styles/common/mixins.scss';

.small {
  font-size: 1.3rem;
}

.profile {
  & > * + * {
    margin-top: 1rem;
  }

  @include ipad {
    display: flex;
    align-items: stretch;

    .form {
      flex: 1 0 calc((8 / 12) * 100%);
    }

    .map {
      flex: 1 0 calc((4 / 12) * 100%);
      min-height: 50rem;
    }

    & > * + * {
      margin-top: 0;
      margin-left: 2rem;
    }
  }
}

.form {
  & > * + * {
    margin-top: 1rem;
  }

  @include ipad {
    display: grid;
    align-content: start;
    gap: 1rem;
    grid-template-columns: repeat(6, 1fr);

    @for $i from 1 through 6 {
      .column-span-#{$i} {
        grid-column: span #{$i};
      }
    }

    & > * + * {
      margin-top: 0;
    }
  }
}

#map {
  margin-top: 10px;
  height: 40rem;
  width: 100%;

  @include ipad {
    height: 100%;
  }
}

select.lowco-textbox {
  width: 100%;
}
</style>
