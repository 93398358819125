<template>
  <!-- Delete confirmation modal -->
  <Modal :isDisplayed="isConfirmationActive" @close-modal="isConfirmationActive = false">
    <p>Êtes-vous sûr de vouloir supprimer cette image de votre profil ?</p>

    <div class="buttons mt-2">
      <Button text="Supprimer" isDanger extraSmall @buttonClick="onDeleteVideo" />
      <Button text="Annuler" isGrey extraSmall @buttonClick="isConfirmationActive = false" />
    </div>
  </Modal>

  <!-- Add video modal -->
  <Modal :isDisplayed="isAddModalActive" @close-modal="isAddModalActive = false">
    <h2 class="mt-2 mb-1">Insérez le lien de la vidéo YouTube que vous souhaitez ajouter</h2>
    <input
      type="text"
      class="lowco-textbox"
      placeholder="https://youtu.be/aqz-KE-bpKQ"
      :value="videoToAdd.url"
      @input="onVideoUrlChange"
    />

    <p class="mt-1">
      Vous n&apos;avez pas encore de vidéo mise en ligne sur YouTube et vous ne savez
      pas comment faire ?
      <a
        class="link"
        href="https://support.google.com/youtube/answer/57407/importer-des-vid%C3%A9os-ordinateur?co=GENIE.Platform%3DDesktop&hl=fr"
        target="_blank"
      >Cliquez ici !</a>
    </p>

    <div class="errors mb-2" v-if="errors.length">
      <div
        v-for="error in errors"
        :key="error"
        class="lowco-textbox lowco-textbox-error mt-1"
      >{{ error }}</div>
    </div>

    <div class="btn">
      <Button
        class="mt-2 is-right"
        text="Ajouter"
        :disabled="!videoToAdd.url.trim()"
        extraSmall
        @buttonClick="onVideoAdded"
      />
    </div>
  </Modal>

  <div>
    <div class="videos-wrapper">
      <div class="videos">
        <div class="videos__item" v-for="video in videos" :key="video.id">
          <Frame :url="video.mediaUrl" />
          <p class="videos__item__delete link mt-1" @click="onDeleteClick(video)">Supprimer la vidéo</p>
        </div>
      </div>

      <Button
        class="mt-2"
        text="Ajouter une vidéo"
        @buttonClick="isAddModalActive = true"
        alternate
        extraSmall
      />
    </div>
  </div>
</template>

<script>
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faTimes } from '@fortawesome/pro-light-svg-icons';

import Modal from '../common/Modal.vue';
import Button from '../common/Button.vue';
import Frame from '../common/Frame.vue';

library.add(faTimes);

export default {
  name: 'Videos',
  emits: ['on-video-added', 'on-video-deleted'],
  props: {
    FontAwesomeIcon,
    videos: Array,
  },
  components: {
    Button,
    Modal,
    Frame,
  },
  data() {
    return {
      isConfirmationActive: false,
      isAddModalActive: false,
      errors: [],
      videoToDelete: null,
      videoToAdd: {
        url: '',
      },
    };
  },
  watch: {
    isConfirmationActive(value) {
      if (!value) {
        this.videoToDelete = null;
      }
    },
    isAddModalActive(value) {
      if (!value) {
        this.errors = [];
      }
    },
  },
  methods: {
    onDeleteClick(video) {
      this.videoToDelete = video;
      this.isConfirmationActive = true;
    },
    onDeleteVideo() {
      this.$emit('on-video-deleted', this.videoToDelete.id);
      this.isConfirmationActive = false;
    },
    onVideoUrlChange(event) {
      const url = event.target.value;

      this.videoToAdd = { ...this.videoToAdd, url };
    },
    onVideoAdded() {
      this.errors = [];
      const urlCheckRegExp = new RegExp(
        /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=|\?v=)([^#&?]*).*/,
      );

      if (!urlCheckRegExp.test(this.videoToAdd.url)) {
        this.errors = [...this.errors, 'L\'URL n\'est pas valide.'];
        return;
      }

      const url = `https://www.youtube.com/embed/${this.getVideoId(this.videoToAdd.url)}`;

      this.$emit('on-video-added', { url });
      this.videoToAdd = { url: '' };
      this.isAddModalActive = false;
    },
    getVideoId(videoUrl) {
      if (videoUrl.includes('v=')) {
        const [, videoId] = videoUrl.split('v=');
        const ampersandPos = videoId.indexOf('&');

        if (ampersandPos !== -1) {
          return videoId.substring(0, ampersandPos);
        }

        return videoId;
      }

      const [videoId] = videoUrl.split('/').slice(-1);
      return videoId;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../assets/styles/common/variables.scss";
@import "../../assets/styles/common/mixins.scss";

.videos-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.videos {
  padding: 2rem 0;

  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fit, 25rem);
  gap: 2rem;
  justify-content: center;

  &__item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    &__delete {
      display: inline-block;
      cursor: pointer;
    }
  }
}

.buttons {
  @include spacing-children("vertical", 1rem);

  @include ipad {
    display: flex;
    align-items: center;
    justify-content: center;

    @include spacing-children("vertical", 0);
    @include spacing-children("horizontal", 1rem);
  }
}

.btn {
  display: flex;

  .is-right {
    margin-left: auto;
  }
}
</style>
