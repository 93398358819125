<script setup>
import { ref, computed, onMounted, watch } from 'vue';
import { useRouter, onBeforeRouteLeave } from 'vue-router';
import { useI18n } from 'vue-i18n';
import { useToast } from 'vue-toastification';

import Button from '@/components/common/Button.vue';
import Accordion from '@/components/common/Accordion.vue';
import Modal from '@/components/common/Modal.vue';
import Openings from '@/components/profile/Openings.vue';
import Images from '@/components/profile/Images.vue';
import ProfileDetails from '@/components/profile/Profile.vue';
import Layout from '@/components/common/Layout.vue';
import Videos from '@/components/profile/Videos.vue';
import initialBadges from '@/constants/badges.constants';
import mediaTypes from '@/constants/mediaTypes.constants';
import lowcoApi from '@/api/lowco-api';
import useCurrentCompany from '@/composables/useCurrentCompany';
import useWindowSize from '@/composables/useWindowSize';
import companyUtils from '@/utils/company.utils';
import useAuthenticatedUser from '@/composables/useAuthenticatedUser';
import LoyaltyImport from '@/components/profile/LoyaltyImport.vue';
import useCompanyOffers from '@/composables/useCompanyOffers';
import config from '@/config';
import useCurrentCompanyDetails from '@/composables/useCurrentCompanyDetails';

const PWA_LINK = config.postLogoutRedirect;

const router = useRouter();
const { t } = useI18n();
const toast = useToast();

const { isIPadOrGreater } = useWindowSize();
const { isAdmin } = useAuthenticatedUser();
const {
  currentCompanyId,
  companies,
  fetchCompanies,
  isFetchingCompanies,
} = useCurrentCompany();

const {
  currentCompany: company,
  updateCompany,
  fetching: isLoading,
} = useCurrentCompanyDetails();

const { useLoyaltyCard } = useCompanyOffers();

const companyToSave = ref(null);
const badges = ref(initialBadges);
const hasInvoiceAddress = ref(false);
const hasUnsavedChanges = ref(false);
const intendedRoute = ref(null);
const isWarningModalOpen = ref(false);
const showLoyaltyImport = ref(false);

// Computed properties
const hasMoreThanOneCompany = computed(() => companies.value.length > 1);
const companySelectedBadges = computed(() =>
  badges.value.filter((b) => b.isChecked).map((b) => b.id),
);
const badgesLink = computed(() => `${PWA_LINK}/badges`);
const images = computed(
  () => companyToSave.value.medias
    .filter((m) => m.mediaType === mediaTypes.IMAGE),
);

const videos = computed(
  () => companyToSave.value.medias
  .filter((m) => m.mediaType === mediaTypes.VIDEO),
);

const selectCompanyText = computed(() =>
  isIPadOrGreater.value
    ? 'Vous pouvez changer de point de vente via la liste déroulante située en haut de page.'
    : "Accédez à vos différents points de vente via la liste déroulante sur la page 'Plus'.",
);

const canSubmit = computed(
  () => companyUtils.validate(companyToSave.value, hasInvoiceAddress.value).valid,
);
const unvalidFields = computed(
  () => companyUtils.validate(companyToSave.value, hasInvoiceAddress.value).unvalidFields,
);

// Methods
const setCompany = (value, hasInvoiceAdd) => {
  if (value) {
    companyToSave.value = { ...value };
    hasUnsavedChanges.value = true;
  }

  if (typeof hasInvoiceAdd === 'boolean') {
    hasInvoiceAddress.value = hasInvoiceAdd;
  }
};

const isFieldValid = (fieldName) => !unvalidFields.value.includes(fieldName);

const handleDescriptionChange = (event) => {
  const { name, value } = event.target;
  hasUnsavedChanges.value = true;

  companyToSave.value = {
    ...companyToSave.value,
    [name]: value,
  };
};

const addImage = async (image) => {
  const { blob, name } = image;

  const formData = new FormData();

  formData.append('Files', blob, name);

  try {
    const { medias } = await lowcoApi.addImageToCompany(currentCompanyId.value, formData);

    companyToSave.value = {
      ...companyToSave.value,
      medias,
    };
  } catch (error) {
    // Handle error
  }
};

const addVideo = async (video) => {
  try {
    const { medias } = await lowcoApi.addVideoToCompany(currentCompanyId.value, video);

    companyToSave.value = {
      ...companyToSave.value,
      medias,
    };
  } catch (err) {
    // Handle error
  }
};

const initBadges = () => {
  badges.value = badges.value.map((b) => {
    if (company.value.badges.includes(b.id)) {
      return {
        ...b,
        isChecked: true,
      };
    }

    return {
      ...b,
      isChecked: false,
    };
  });
};

const save = async () => {
  companyToSave.value.badges = companySelectedBadges.value;
  companyToSave.value.vat = companyToSave.value.vat.replace(/[\s.]/g, '');

  updateCompany(companyToSave.value, () => {
    hasUnsavedChanges.value = false;
    toast.success('Sauvegarde effectuée avec succès');
    fetchCompanies();
  });
};

// eslint-disable-next-line
const activeBadgeUrl = (activeBadge) => require(`../assets/images/badges/${activeBadge}.svg`);

const toggleBadge = (badge) => {
  // eslint-disable-next-line no-param-reassign
  badge.isChecked = !badge.isChecked;
  hasUnsavedChanges.value = true;
};

const updateStatus = async (newStatus) => {
  try {
    await lowcoApi.updateCompanyStatus(currentCompanyId.value, newStatus);
  } catch (error) {
    // Handle error
  }
};

const deleteMedia = async (mediaId) => {
  try {
    await lowcoApi.removeCompanyMedia(currentCompanyId.value, mediaId);

    companyToSave.value = {
      ...companyToSave.value,
      medias: companyToSave.value.medias.filter((m) => m.id !== mediaId),
    };
  } catch (error) {
    // Handle error
  }
};

const leave = async () => {
  initBadges();
  hasUnsavedChanges.value = false;
  router.push(intendedRoute.value);
};

const saveAndLeave = async () => {
  await save();
  leave();
};

// Watchers
watch(
  company,
  (value, oldValue) => {
  if (!value || value === oldValue) {
    return;
  }

  companyToSave.value = { ...value };
  },
  { immediate: true },
);

watch(
  companyToSave,
  (value, oldValue) => {
    if (!value || value === oldValue) {
      return;
    }

    if (!value.billingAddress) {
      companyToSave.value.billingAddress = {
        street: '',
        zipCity: '',
        city: '',
      };
    }

    hasInvoiceAddress.value = !!value.billingAddress?.street;

    initBadges();
  },
  { immediate: true }
);

onBeforeRouteLeave((to, from, next) => {
  if (hasUnsavedChanges.value) {
    intendedRoute.value = to;
    isWarningModalOpen.value = true;

    return;
  }

  next();
});
</script>

<template>
  <Layout :loading="isLoading || isFetchingCompanies">
    <div v-if="companyToSave">
      <template v-if="companyToSave">
        <Button
          v-if="isAdmin"
          class="tar mb-2"
          :disabled="!useLoyaltyCard"
          @buttonClick="showLoyaltyImport = true"
        >
          {{ t('pages.profile.importLoyaltyButton') }}
        </Button>

        <div v-if="hasMoreThanOneCompany" class="lowco-textbox lowco-textbox-warn mb-1">
          {{ selectCompanyText }}
        </div>
        <Accordion title="Profil" defaultDisplayContent>
          <ProfileDetails
            :company="companyToSave"
            :unvalidFields="unvalidFields"
            @company-change="setCompany"
            @status-change="updateStatus"
          />
        </Accordion>
        <Accordion title="Horaires" :hasError="!isFieldValid('openings')">
          <Openings :company="companyToSave" @company-change="setCompany" />
        </Accordion>
        <Accordion
          title="Offre et démarche"
          :hasError="!isFieldValid('descriptionPhilo') || !isFieldValid('descriptionOffer')"
        >
          <div class="mt-1">
            <label for="descriptionOffer">Description de votre offre</label>
            <textarea
              required
              type="text"
              id="descriptionOffer"
              v-model="companyToSave.descriptionOffer"
              @change="handleDescriptionChange"
              name="descriptionOffer"
              maxlength="300"
              :class="[
                'lowco-textbox',
                { 'lowco-textbox-error': !isFieldValid('descriptionOffer') },
              ]"
            />
          </div>
          <div class="mt-1">
            <label for="descriptionPhilo">Description de votre démarche</label>
            <textarea
              required
              type="text"
              id="descriptionPhilo"
              v-model="companyToSave.descriptionPhilo"
              @change="handleDescriptionChange"
              name="descriptionPhilo"
              rows="8"
              maxlength="600"
              :class="[
                'lowco-textbox',
                { 'lowco-textbox-error': !isFieldValid('descriptionPhilo') },
              ]"
            ></textarea>
          </div>
          <div class="mt-1">
            <label>Badges</label>
          </div>
          <p class="small">
            Ne sélectionnez que les badges qui vous représentent le mieux pour ne pas créer de
            fausses attentes ! Ils servent de filtre lors de la recherche.
          </p>
          <div class="link-wrapper">
            <a class="link" :href="badgesLink" target="_blank">Découvrir les badges</a>
          </div>
          <div class="badge-list">
            <div
              class="select-badge"
              v-for="badge in badges"
              :key="badge.id"
              :class="{ 'select-badge-unselected': !badge.isChecked }"
            >
              <img
                class="badge"
                :src="activeBadgeUrl(badge.image)"
                :alt="badge.name"
                @click="toggleBadge(badge)"
              />
            </div>
          </div>
        </Accordion>
        <Accordion title="Images du profil" :hasError="!isFieldValid('medias')">
          <Images :images="images" @on-image-added="addImage" @on-image-delete="deleteMedia" />
        </Accordion>
        <Accordion title="Vidéos">
          <Videos :videos="videos" @on-video-deleted="deleteMedia" @on-video-added="addVideo" />
        </Accordion>
        <div class="lowco-textbox lowco-textbox-warn my-1">
          RENDEZ-VOUS SUR LA PAGE
          <router-link to="/catalog">CATALOGUE</router-link>POUR ENREGISTRER VOS ARTICLES !
        </div>
        <div class="save">
          <Button
            class="save__button"
            text="Sauvegarder"
            :disabled="!canSubmit"
            @buttonClick="save"
            extraSmall
          />
        </div>

        <Modal
          :isFreezed="isLoading"
          :isDisplayed="isWarningModalOpen"
          @close-modal="isWarningModalOpen = false"
        >
          <h2 class="mb-2">Attention</h2>
          <p>Vous avez des changements non sauvegardés !</p>
          <p>Voulez-vous continuer ?</p>
          <div class="buttons">
            <Button extraSmall text="Continuer sans sauvegarder" isDanger @buttonClick="leave" />
            <Button extraSmall text="Sauvegarder et continuer" @buttonClick="saveAndLeave" />
          </div>
        </Modal>

        <LoyaltyImport
          :visible="showLoyaltyImport"
          :companyId="currentCompanyId"
          :isAdminImport="isAdmin"
          @close="showLoyaltyImport = false"
        />
      </template>
    </div>
    <div v-else>No Enterprise</div>
  </Layout>
</template>

<style lang="scss" scoped>
@import '@/assets/styles/common/variables.scss';
@import '@/assets/styles/common/mixins.scss';

.bold {
  font-weight: 600;
}

.save {
  position: fixed;
  bottom: calc(7rem + env(safe-area-inset-bottom));
  left: calc(2rem + env(safe-area-inset-left));
  right: calc(2rem + env(safe-area-inset-right));

  text-align: right;
  margin-top: 2rem;
  z-index: 9999;

  &__button {
    width: 100%;
  }

  @include ipad {
    position: static;
    z-index: 0;

    &__button {
      min-width: 30rem;
      width: auto;
    }
  }
}

.badge-list {
  margin-top: 2rem;

  display: grid;
  grid-template-columns: repeat(auto-fill, 9rem);
  justify-content: center;
  gap: 1rem;

  @include ipad {
    justify-content: start;
  }
}

.select-badge {
  width: 9rem;
  display: inline-block;
  cursor: pointer;
  text-align: center;

  > img {
    max-width: 15rem;
  }

  &-unselected {
    img {
      filter: grayscale(100%);
    }
  }
}

.small {
  font-size: 1.3rem;
  margin: 5px 0 10px 0;
}

.link-wrapper {
  display: flex;
  justify-content: center;
  align-self: start;
}

.link {
  font-size: 1.8rem;
  color: $dark-green;
  margin: 2rem auto 0 auto;
}

.lowco-textbox {
  a {
    font-size: inherit;
    text-decoration: underline;
  }
}

.buttons {
  margin-top: 2rem;
  @include spacing-children('vertical', 1rem);

  @include sm {
    display: flex;
    justify-content: flex-end;
    @include spacing-children('horizontal', 1rem);
    @include spacing-children('vertical', 0);
  }
}
</style>
