const badges = [
  {
    id: 'BadgeBulk',
    name: 'vrac',
    image: 'bulk',
    isChecked: false,
  },
  {
    id: 'BadgeSecondHand',
    name: 'seconde main',
    image: 'second_hand',
    isChecked: false,
  },
  {
    id: 'BadgeLocalProduction',
    name: 'produit sur place',
    image: 'local_production',
    isChecked: false,
  },
  {
    id: 'BadgeLocalTransformation',
    name: 'transformé sur place',
    image: 'local_transformation',
    isChecked: false,
  },
  {
    id: 'BadgeShortCircuit',
    name: 'circuit court',
    image: 'short_circuit',
    isChecked: false,
  },
  {
    id: 'BadgeSustainableLabels',
    name: 'labels durables',
    image: 'sustainable_labels',
    isChecked: false,
  },
  {
    id: 'BadgeFairtrade',
    name: 'commerce équitable',
    image: 'fairtrade',
    isChecked: false,
  },
  {
    id: 'BadgeSmallProducers',
    name: 'petit producteur',
    image: 'small_producers',
    isChecked: false,
  },
  {
    id: 'BadgeLocalEconomy',
    name: 'ancrage local',
    image: 'local_economy',
    isChecked: false,
  },
  {
    id: 'BadgeZeroWaste',
    name: 'zéro déchet',
    image: 'zero_waste',
    isChecked: false,
  },
  {
    id: 'BadgeAgroecology',
    name: 'agroécologie',
    image: 'agroecology',
    isChecked: false,
  },
  {
    id: 'BadgeCircularEconomy',
    name: 'économie circulaire',
    image: 'circular_economy',
    isChecked: false,
  },
];

export default badges;
