<template>
  <Modal :isDisplayed="isConfirmationActive" @close-modal="toggleModal">
    <p>Êtes-vous sûr de vouloir supprimer cette image de votre profil ?</p>

    <div class="buttons mt-2">
      <Button text="Supprimer" isDanger extraSmall @buttonClick="onDeleteImage" />
      <Button text="Annuler" isGrey extraSmall @buttonClick="toggleModal" />
    </div>
  </Modal>

  <Modal :isDisplayed="showImageCropper" @close-modal="showImageCropper = false">
    <ImageCropper :image-source="imgSrc" @change="handleImageSaving">
      <template v-slot:header>
        <h4>Ajouter une image</h4>
        <p class="mb-1">Veuillez définir votre image.</p>
        <p>
          Sélectionnez une image
          <strong>horizontale</strong> de max 2Mo.
        </p>
        <p class="mb-2">
          Si vous sélectionnez du vide de part et d'autre d'une image verticale,
          il sera comptabilisé dans la taille totale de l'image affichée.
        </p>
      </template>
    </ImageCropper>
  </Modal>

  <div>
    <p class="small">
      Mettez votre activité en valeur avec des images de profil au format jpg ou png de maximum 2Mo.
      Elles apparaîtront dans l’ordre dans lequel vous les chargez.
    </p>
    <div class="images-wrapper">
      <div class="col-12 images">
        <div class="images__item" v-for="image in images" :key="image.id">
          <span class="images__item__times" @click="onTimesClick(image)">
            <FontAwesomeIcon :icon="['fal', 'times']" />
          </span>
          <ResponsiveImage :image="image.mediaUrl" is4by3 />
        </div>
      </div>
      <div class="errors mb-2" v-if="errors.length">
        <div
          v-for="error in errors"
          :key="error"
          class="lowco-textbox lowco-textbox-error mt-1"
        >{{ error }}</div>
      </div>
      <input
        type="file"
        accept="image/png, image/jpeg, image/jpg"
        ref="file"
        class="hidden"
        @change="loadImage"
      />
      <Button
        class="add-image"
        text="Ajouter une image"
        :disabled="images.length > 4"
        alternate
        extraSmall
        @buttonClick="addImage"
      />

      <MessageBanner class="warning-msg" v-if="images?.length > 4">
        <p>Le nombre maximal d'images est de 5.</p>
        <p>Veuillez supprimer une ou des image(s) existante(s) afin d'en ajouter de nouvelles.</p>
      </MessageBanner>
    </div>
  </div>
</template>

<script>
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

import { faTimes } from '@fortawesome/pro-light-svg-icons';

import Modal from '@/components/common/Modal.vue';
import MessageBanner from '@/components/common/MessageBanner.vue';
import ImageCropper from '@/components/common/ImageCropper.vue';

import Button from '../common/Button.vue';
import ResponsiveImage from '../common/ResponsiveImage.vue';

library.add(faTimes);

export default {
  name: 'Images',
  emits: ['on-image-added', 'on-image-delete'],
  components: {
    Modal,
    Button,
    ResponsiveImage,
    FontAwesomeIcon,
    MessageBanner,
    ImageCropper,
  },
  props: {
    images: Array,
  },
  data: () => ({
    isConfirmationActive: false,
    imgSrc: null,
    fileName: 'blob.jpg',
    showImageCropper: false,
    imageToDelete: null,
    errors: [],
  }),
  watch: {
    isConfirmationActive(value) {
      if (!value) {
        this.imageToDelete = null;
      }
    },
  },
  methods: {
    addImage() {
      this.$refs.file.click();
    },
    loadImage(event) {
      const file = event.target.files[0];

      this.errors = [];

      const fileNameRegExp = new RegExp(/(\.png|jpeg|jpg|svg)$/, 'i');
      this.fileName = file.name;

      if (!fileNameRegExp.test(file.name)) {
        this.errors = [
          ...this.errors,
          'Le fichier doit être une image du format JPEG/JPG/PNG/SVG',
        ];

        return;
      }

      const reader = new FileReader();
      reader.onload = (e) => {
        this.imgSrc = e.target.result;
      };

      reader.readAsDataURL(file);
      this.showImageCropper = true;
    },
    handleImageSaving(result) {
      if (!this.showImageCropper) {
        return;
      }

      const { image, errors } = result;

      if (errors?.length) {
        this.errors = [
          ...this.errors,
          ...errors,
        ];

        this.showImageCropper = false;

        return;
      }

      this.$emit('on-image-added', { ...image, name: this.fileName });

      this.showImageCropper = false;
    },
    toggleModal() {
      this.isConfirmationActive = !this.isConfirmationActive;
    },
    onTimesClick(image) {
      this.toggleModal();
      this.imageToDelete = image;
    },
    onDeleteImage() {
      this.$emit('on-image-delete', this.imageToDelete.id);
      this.toggleModal();
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../assets/styles/common/mixins.scss";

.add-image {
  margin-top: 10px;
}

.images-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.images {
  padding: 2rem 0;

  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fit, 20rem);
  gap: 2rem;
  justify-content: center;

  &__item {
    position: relative;

    &__times {
      background-color: $dark-green;
      color: #fff;
      font-size: 1.2rem;
      padding: 0 0.6rem;
      border-radius: 50%;

      position: absolute;
      z-index: 9;
      top: 0;
      right: 0;

      transform: translate(40%, -40%);
      cursor: pointer;
    }
  }
}

.small {
  font-size: 1.3rem;
}

.buttons {
  @include spacing-children("vertical", 1rem);

  @include ipad {
    display: flex;
    align-items: center;
    justify-content: center;

    @include spacing-children("vertical", 0);
    @include spacing-children("horizontal", 2rem);
  }
}
.warning-msg {
  margin-top: 2rem;
}
</style>
