<template>
  <div class="accordion">
    <h2
      v-bind:class="{ 'pointer': !cannotBeClosed, 'small' : smallTitle, 'error': hasError }"
      v-on:click="toggleDisplayContent()"
    >
      <span>{{ title }}</span>
      <i
        class="icon-down-arrow"
        v-bind:class="{ 'hide': !isDisplayContent }"
        v-if="!cannotBeClosed"
      ></i>
    </h2>
    <div
      class="content"
      v-show="isDisplayContent"
    >
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Accordion',
  data() {
    return {
      displayContent: null,
    };
  },
  props: {
    cannotBeClosed: Boolean,
    defaultDisplayContent: Boolean,
    title: String,
    smallTitle: Boolean,
    hasError: Boolean,
  },
  methods: {
    toggleDisplayContent() {
      if (this.cannotBeClosed) {
        return;
      }

      if (this.displayContent === null) {
        this.displayContent = this.defaultDisplayContent;
      }

      this.displayContent = !this.displayContent;
    },
  },
  computed: {
    isDisplayContent() {
      if (this.displayContent === null) {
        return this.defaultDisplayContent;
      }

      return this.displayContent;
    },
  },
};
</script>
<style lang="scss" scoped>
@import "../../assets/styles/common/variables.scss";
@import "../../assets/styles/common/mixins.scss";

h2 {
  padding: 10px 20px;
  margin-left: -20px;
  margin-right: -20px;
  display: flex;
  font-size: 2rem;
  justify-content: space-between;
  margin-bottom: 10px;
  border-bottom: 1px solid $alternate-green;

  i {
    transition: transform 350ms ease;
    display: inline-block;
  }

  &.small {
    font-size: 2rem;
  }

  &.error {
    color: $error;
  }

  @include ipad {
    font-size: 3rem;
  }
}

.accordion {
  position: relative;
  margin-top: 1rem;
}

.icon-down-arrow {
  position: absolute;
  top: 1.3rem;
  right: 3rem;

  &.hide {
    transform: rotate(-90deg);
  }
}
</style>
