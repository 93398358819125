<template>
  <div class="box warning-banner">
    <slot></slot>
  </div>
</template>

<script>

export default {
  name: 'MessageBanner',
};
</script>

<style lang="scss" scoped>
.warning-banner {
  padding: 1rem;
  background: #e8c8ab;
  border: 2px solid #af6d31;
  color: $dark-green;
  border-radius: 0.5rem;
}
</style>
