const days = [
  {
    id: 'monday',
    name: 'Lundi',
  },
  {
    id: 'tuesday',
    name: 'Mardi',
  },
  {
    id: 'wednesday',
    name: 'Mercredi',
  },
  {
    id: 'thursday',
    name: 'Jeudi',
  },
  {
    id: 'friday',
    name: 'Vendredi',
  },
  {
    id: 'saturday',
    name: 'Samedi',
  },
  {
    id: 'sunday',
    name: 'Dimanche',
  },
];

export default days;
