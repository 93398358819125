<template>
  <div class="frame">
    <iframe
      class="frame__video"
      :src="url"
      title="YouTube video player"
      frameborder="0"
      allow="accelerometer;
      autoplay;
      clipboard-write;
      encrypted-media;
      gyroscope;
      picture-in-picture"
      allowfullscreen
    ></iframe>
  </div>
</template>

<script>
export default {
  name: 'Frame',
  props: {
    url: String,
  },
};
</script>

<style lang="scss" scoped>
.frame {
  position: relative;
  overflow: hidden;
  width: 100%;
  padding-top: 56.25%; // 16:9 aspect ratio

  &__video {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
  }
}
</style>
